<template>
  <div>
    <!--    class="backsBox" :style="{backgroundImage:'url('+ backImg +')'}"-->
    <div class="centerBox" :style="{display: BoxSta}">
      <div class="bannerBox" :class="banners.length<1?'b':''">
        <transition-group tag='ul' name='img'>
          <li v-for='(image,indexs) in banners' :key='indexs' v-show='indexs===mark'>
            <div class="divImg" :style="{backgroundImage: 'url('+image.url+')'}"></div>
          </li>
        </transition-group>
      </div>
      <div>
        <div class="itemBox" v-for="r in menus">
          <div :class="getStyle(r)" :style="r.textStyle" @click="jump(r,0)">
            <p>{{ r.name }}</p>
            <img src="../assets/icons/x.png" style="width: 25px;height: 25px;" v-if="r.id===1">
            <img src="../assets/icons/w.png" style="width: 25px;height: 25px;" v-if="r.id===2">
            <img src="../assets/icons/r.png" style="width: 25px;height: 25px;" v-if="r.id===3">
            <img src="../assets/icons/l.png" style="width: 25px;height: 25px;" v-if="r.id===4">
          </div>
          <div class="itemBox_Rights">
            <div v-if="r.id===3">
              <a :href="getUrl(1)" style="color:#FFFFFF;" @click="outJumpTap(1)">
                <div class="itemBox_Right" :style="{backgroundColor:r.color}">{{ r.nam }}</div>
              </a>
              <a :href="getUrl(1)" style="color:#FFFFFF;" @click="outJumpTap(2)">
                <div class="itemBox_Right" :style="{backgroundColor:r.color}">{{ r.nams }}</div>
              </a>
            </div>
            <div v-else>
              <div class="itemBox_Right" :style="{backgroundColor:r.color}" @click="jump(r,1)">{{ r.nam }}</div>
              <div class="itemBox_Right" :style="{backgroundColor:r.color}" @click="jump(r,3)" v-if="r.namss">{{
                  r.namss
                }}
              </div>
              <div class="itemBox_Right" :style="{backgroundColor:r.color}" @click="jump(r,2)">{{ r.nams }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="copyrightBox">
        <div class="lineOne">
          <div class="lineOneItem"><p style="color:#555555;" @click="conTactJump(0)">联系我们</p></div>
          <!--          <div class="lineOneItem"><a :href="getUrl(0)" style="color:#555555;">我要反馈</a></div>-->
          <div class="lineOneLine">|</div>
          <div class="lineOneItem" @click="footJump('D')">使用规章</div>
          <div class="lineOneLine">|</div>
          <div class="lineOneItem last" @click="footJump('E')">免责事项</div>
        </div>
        <div class="lineTwo">
          <p class="lineTwo_p1"> ©{{ newDate() }}河北百乐百货商业连锁有限公司 </p>
          <p class="lineTwo_p2">
            <img style="margin-bottom: 7px; margin-right: 5px;" src="@/assets/yxtx/xwgk_ba.png">
            <a href="https://beian.miit.gov.cn/#/Integrated/index" style="color: rgba(0, 0, 0, 0.65);">
               网站备案号：冀ICP备11007430号-2</a>
            <span style="margin-left: 15px;">热线电话：400-118-6100</span>
          </p>
        </div>
      </div>
      <div class="footsBox" v-if="!isPc"></div>
    </div>
    <el-dialog title="请登录后进入" :visible.sync="dialogFormVisible" center :width="dialogWidth">
      <el-form ref="loginForm" :model="loginForm" :rules="rules">
        <el-form-item label="账号" :label-width="formLabelWidth" prop="tel">
          <el-input v-model="loginForm.tel" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="密码" :label-width="formLabelWidth" prop="pwd">
          <el-input type="password" v-model="loginForm.pwd" autocomplete="off" :show-password="true"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="onSubmit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {get, postJson} from "@/utils/request";

let deImg = require("@/assets/index/pc-b.jpg");
export default {
  name: "Index",
  components: {},
  data() {
    return {
      menus: [{
        color: '#F57655',
        name: '关于百乐',
        nam: '百乐分享',
        nams: '百乐课堂',
        textStyle: "text-shadow: 1px 1px 0 #cf4b47;",
        id: 1
      }, {
        color: '#89C122',
        name: '文化中心',
        nam: '企业文化',
        nams: '图书借阅',
        textStyle: "text-shadow: 1px 1px 0 #90C122;background-color:#89C122;",
        id: 2
      }, {
        color: '#7E57C5',
        name: '作业标准',
        nam: '考试系统',
        nams: '评议系统',
        textStyle: "text-shadow: 1px 1px 0 #6415FF;background-color:#7E57C5;",
        id: 3
      }, {
        color: '#F9B740',
        name: '我要反馈',
        nam: '招聘中心',
        namss: '加盟合作',
        nams: '招商合作',
        textStyle: "text-shadow: 1px 1px 0 #CCAB7B;background-color:#F9B740;",
        id: 4
      }],
      backImg: '',
      banners: [],
      mark: 0,
      isPc: false,
      BoxSta: 'none',
      dialogFormVisible: !1,
      loginPath: '',
      loginForm: {
        tel: '',
        pwd: ''
      },
      rules: {
        tel: [
          {required: true, message: '请输入登录账号', trigger: 'blur'}
        ],
        pwd: [
          {required: true, message: '请输入登录密码', trigger: 'blur'}
        ]
      },
      formLabelWidth: '43px',
      dialogWidth: '85%'
    }
  },
  created() {
    this.getWebInfo()
    const userAgent = window.navigator.userAgent;
    if (/Mobile|Android|iPhone|iPod|iPad|Windows Phone/i.test(userAgent)) {
      // console.log('移动端')
      this.dialogWidth = '85%'
      this.formLabelWidth = '60px'
    } else {
      // console.log('pc端')
      this.dialogWidth = '27%'
      this.formLabelWidth = '50px'
    }
  },
  methods: {
    async getWebInfo() {
      await get("/api/webPage/getWebPageInfo").then(res => {
        if (res && res.data) {
          let backImg = res.data.backgroundImageUrl || deImg;
          this.banners = res.data.carouselImgList;
          this.play()
          this.setBgImg(backImg)
        } else {
          this.setBgImg(deImg)
        }
      }).catch(() => {
        this.setBgImg(deImg)
      })
    },
    setBgImg(img) {
      this.$nextTick(() => {
        const bodyStyle = document.body.style, // 获取body节点样式
          docEl = document.documentElement,
          docBody = document.body,
          winWidth = docEl.clientWidth || docBody.clientWidth; // 获取浏览器的宽度
        winWidth > 850 ? this.isPc = true : this.isPc = false;
        let sty = "url(" + img + ") #b4daef no-repeat";
        bodyStyle.background = sty;
        bodyStyle.backgroundAttachment = "fixed";
        bodyStyle.backgroundSize = "100% 100%";
        this.BoxSta = 'block';
      });
    },
    play() {
      setInterval(this.autoPlay, 5000)
    },
    autoPlay() {
      if (this.mark < this.banners.length - 1) {
        this.mark++;
      } else {
        this.mark = 0;
      }
    },
    getStyle(r) {
      if (r.id === 1) {
        return 'itemBox_Left a'
      } else if (r.id === 2) {
        return 'itemBox_Left b'
      } else if (r.id === 3) {
        return 'itemBox_Left c'
      } else {
        return 'itemBox_Left d'
      }
    },
    jump(record, index) {
      if (record) {
        let params = {},
          path = 'Home',
          tips = '暂未开放';
        if (record.id === 1) {
          if (index === 0) { //关于百乐
            params = {type: 'F'}
            this.sendClick('A')
          } else if (index === 1) { //分享
            params = {type: 'share'}
            this.sendClick('B')
          } else if (index === 2) { //课堂
            params = {type: 'B'}
          }
        } else if (record.id === 2) {
          if (index === 0) { //文化中心
            params = {type: 'G'}
            this.sendClick('C')
          }
          if (index === 1) { //企业文化
            path = 'showLogin'
          }
          if (index === 2) { //图书借阅
            this.sendClick('E')
            path = 'outJump'
          }
        } else if (record.id === 3) {
          if (index === 0) { //作业标准
            path = 'bzLogin'
          } else if (index === 1) {
            path = 'outJump'
          } else if (index === 2) {
            path = 'outJump'
          }
        } else if (record.id === 4) {
          if (index === 0) { //我要反馈
            path = 'feedback'
            params = {id: ''}
            // path = 'Shops'
            // params = {type: 'shops'}
            this.sendClick('I')
          }
          if (index === 1) { //招聘
            params = {type: 'C'}
            this.sendClick('J')
          }
          if (index === 2) { //招商
            path = 'Trade'
            params = {type: 'trade'}
            this.sendClick('L')
          }
          if (index === 3) { //加盟合作
            params = {type: 'I'}
            this.sendClick('K')
          }
        } else {
          path = ''
        }
        if (path) {
          if (path === 'outJump') {
            window.location.href = process.env.VUE_APP_API_BACK_URL
          } else if (path === 'showLogin') {
            let isLogin = window.localStorage.getItem('token')
            this.loginPath = 'J' //企业文化
            if (isLogin) {
              this.$router.push({
                path: '/Home',
                query: {type: 'J'}
              })
              this.sendClick('D')
            } else {
              this.dialogFormVisible = true
              this.$refs['loginForm'] ? this.$refs['loginForm'].resetFields() : '';
            }
          } else if (path === 'bzLogin') {
            this.loginPath = 'A' //作业标准
            let isLogin = window.localStorage.getItem('token')
            if (isLogin) {
              this.$router.push({
                path: '/CateIndex',
                query: {cid: 0, type: 'A', t: '1'}
              })
              this.sendClick('F')
            } else {
              this.dialogFormVisible = true
              this.$refs['loginForm'] ? this.$refs['loginForm'].resetFields() : '';
            }
          } else {
            this.$router.push({
              path: path,
              query: params
            })
          }
        } else {
          this.$message.info(tips)
        }
      } else {
        this.$message.info('暂未开放')
      }
    },
    async sendClick(e) {
      await postJson("/api/webPage/clickModule", {
        moduleId: e,
        merchantId: process.env.VUE_APP_MerId
      }).then(() => {
      });
    },
    newDate() {
      return new Date().getFullYear()
    },
    getUrl(r) {
      if (r === 0) {
        // return 'https://www.wjx.cn/vm/PpkaCKq.aspx#' !!!
        // return 'https://www.wjx.top/vm/Q6T3I38.aspx#'
        // return 'http://o086ryln3n2aonyc.mikecrm.com/aSzIcty'
        return process.env.VUE_APP_API_BASE_URL + '/api/feedback/getFeedbackUrl'
      } else if (r === 1) {
        return process.env.VUE_APP_API_BACK_URL
      }
    },
    outJumpTap(e) {
      if (e === 1) { //考试标准
        this.sendClick('G')
      } else { //评议系统
        this.sendClick('H')
      }
    },
    footJump(e) {
      let param = {
        pageSize: 1,
        pageNum: 1,
        type: e
      };
      get('/api/wiki/apiGetWikiList', param).then(res => {
        this.$router.push({
          path: "/article",
          query: {
            id: res.data.rows[0].id
          }
        })
      })
    },
    conTactJump() {
      this.$router.push({
        path: "/Shops",
        query: {
          type: 'shops'
        }
      })
    },
    onSubmit() {
      let array = this.loginForm;
      if (array.tel === "") {
        this.$notify({
          title: '提示',
          message: '请填写登录账号',
          type: 'warning'
        });
        return
      }
      if (array.pwd === "") {
        this.$notify({
          title: '提示',
          message: '请填写登录密码',
          type: 'warning'
        });
        return
      }
      // blbh/api/wiki/login
      postJson("/api/wiki/login", {
        account: array.tel, password: array.pwd, merchantId: process.env.VUE_APP_MerId
      }).then(res => {
        if (res.result) {
          this.dialogFormVisible = false
          this.$message.success('登录成功')
          window.localStorage.setItem('token', res.data.token)
          setTimeout(() => {
            if (this.loginPath === 'A') { //作业标准A
              this.$router.push({
                path: '/CateIndex',
                query: {cid: 0, type: 'A', t: '1'}
              })
            } else { //企业文化J
              this.$router.push({
                path: '/Home',
                query: {type: 'J'}
              })
            }
          }, 500)
        }
      });
    }
  }
}
</script>

<style scoped>

* {
  list-style-type: none;
}

.bannerBox {
  width: 100%;
  height: 188px;
  margin: 10px auto;
  border-radius: 10px;
  overflow: hidden;
  z-index: 1;
  position: relative;
}

.bannerBox ul li {
  position: absolute;
  width: 100%;
}

.bannerBox .divImg {
  background-position: 0;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 188px;
  border-radius: 10px;
}

.bannerBox.b {
  background-image: url('../assets/index/topImg.jpg');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.img-enter-active,
.img-leave-active {
  transition: all 4s;
}

.img-enter,
.img-leave-to {
  opacity: 0;
}

.img-enter-to,
.img-leave {
  opacity: 1;
}

.backBox {
  background-color: #B4DAEF;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  overflow-y: scroll;
}

.centerBox {
  width: 95%;
  margin: 0 auto;
  position: relative;
  z-index: 10;
}

.itemBox {
  margin: 3px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
}

.itemBox:hover {
  cursor: pointer;
}

.itemBox:first-child {
  border-radius: 10px 10px 0 0;
}

.itemBox:last-child {
  border-radius: 0 0 10px 10px;
}

.itemBox_Left {
  width: 50%;
  height: 111.5px;
  color: #ffffff;
  font-size: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.itemBox_Left.a {
  background: url("../assets/icons/b1.png") 2% 100% no-repeat #F57655;
}

.itemBox_Left.b {
  background: url("../assets/icons/b2.png") 2% 100% no-repeat #89C122;
}

.itemBox_Left.c {
  background: url("../assets/icons/b3.png") 2% 88% no-repeat #7E57C5;
}

.itemBox_Left.d {
  background: url("../assets/icons/b4.png") 2% 100% no-repeat #F9B740;
  height: 160px;
}

.itemBox_Rights {
  width: 50%;
}

.itemBox_Right {
  width: 100%;
  height: 55px;
  line-height: 55px;
  margin: 1px;
  color: #ffffff;
  text-align: center;
  font-size: 17px;
}

.copyrightBox {
  width: 100%;
  background-color: #ffffff;
  border-radius: 7px;
  min-height: 220px;
}

.lineOne {
  line-height: 60px;
  border-bottom: 1px solid #f2f2f2;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  font-size: 16px;
}

.lineOneItem {
  color: #555555;
}

.lineOneItem:hover {
  cursor: pointer;
}

.lineOneLine {
  width: 70px;
  text-align: center;
}

.lineTwo {
  padding: 10px;
  text-align: center;
}

.footsBox {
  widht: 100%;
  height: 50px;
  background-color: unset;
}

@media only screen and (max-width: 1000px) {
  .backBox {
    background-repeat: no-repeat;
  }

  .centerBox {
    width: 92%;
    top: 0vh;
  }

  .itemBox_Left {
    width: 50%;
    height: 107px;
    font-size: 16px;
  }

  .itemBox_Right {
    height: 53px;
    line-height: 53px;
    font-size: 15px;
  }

  .bannerBox {
    height: 153px;
  }

  .bannerBox .divImg {
    height: 153px;
  }

  .itemBox {
    margin: 0 auto;
  }

  .lineOne {
    line-height: 49px;
    font-size: 15px;
    margin: 8px auto;
  }

  .lineOneLine {
    width: 30px;
  }

  .lineTwo_p2 {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}

/*屏幕宽度大于1000*/
@media only screen and (min-width: 1000px) {
  .centerBox {
    width: 33%;
  }

  .itemBox_Left.d {
    height: 167px;
  }
}
</style>
